import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';

import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './numbersBlock.style';

const NumbersBlock = ({ row, col, colHeading, colImage }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        numberData {
          image {
            publicURL
          }
          slogan
          title
          text
          lists {
            id
            text
          }
        }
      }
    }
  `);

  const {
    slogan,
    title,
    title2,
    text,
    lists,
    image,
  } = data.charityJson.numberData;

  return (
    <BlockWrapper id="numbersBlock">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...colImage}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...colHeading}>
            <ContentWrapper>
              <Heading as="h5" content="Interactive asynchronous connections" />
              <Heading content="Facilitate your company culure and prevent isolation" />
              <Heading as="h3" content="With Remote Mentors, you create interactive asynchronous connections between your team members. Wherever and whenever." />
              {/* <Text className="light-text" content={text} /> */}
              {/* 
              <a className="learn__more-btn" href="#ready">
                <span className="hyphen" />
                <span className="btn_text">pour pass</span>
              </a> */}
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// NumbersBlock style props
NumbersBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// NumbersBlock default style
NumbersBlock.defaultProps = {
  // NumbersBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // NumbersBlock col default style
  colHeading: {
    width: ['100%', '80%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  colImage: {
    width: ['100%', '100%', '66%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default NumbersBlock;
