import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;

  // background: #7440eb08;
  background: ${themeGet('colors.uiPurpleBlueGradient', '#27E8A2')};

  padding: 81px 0 51px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }

  .row {
    // align-items: center;
  }

  h2,
  h5,
  p {
    color: ${themeGet('colors.white', '#27E8A2')};
  }

  h5 {
    max-width: 300px;
  }
`;

export const ImageWrapper = styled.div`
  padding-right: 20px;
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h2, h5, p {
    color: ${themeGet('colors.white', '#27E8A2')};
  }

  h5 {
    max-width: 300px;
  }  

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    // color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 480px) {
      max-width: 227px;
    }
  }

  h2 {
    max-width: 600px;
  }


  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.primary', '#27E8A2')};
    }
  }
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 1.6;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 1rem;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const SliderArea = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 5rem;

  * {
    text-align: center;
  }

  h2,
  h5,
  p {
    color: ${themeGet('colors.white', '#27E8A2')};
  }

  #benefitsSlider {
  }

  .glide__controls {
    display: none !important;
  }

  .glide__slide {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .glide__slide--active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
`;

export default BlockWrapper;
