import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;
  padding: 81px 0 51px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }

  .row {
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .col {
    @media only screen and (max-width: 991px) {
      width: 100%;
      max-width: 700px;
    }
  }

  .questionList {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
      padding-top: 12rem;
    }

    li {
      @media only screen and (min-width: 992px) {
        margin: 0 2rem 1rem 2rem;
      }

      @media only screen and (max-width: 991px) {
        margin-bottom: 2rem;
      }

      display: flex;

      .icon-list {
        margin-right: 1rem;

        @media only screen and (min-width: 992px) {
          margin-right: 2rem;
        }

        color: ${themeGet('colors.uiPurpleBlue', '#27E8A2')};

        &.highlightTickMark {
          color: ${themeGet('colors.uiGreen', '#27E8A2')};
        }
      }

      h4 {
        font-size: 1.5rem;
        line-height: 1.2;
        margin: 0 0 0.3em;
      }

      p {
        margin: 0;
      }
    }

    .highlight {
      margin: 2rem 0;
      border-radius: 4px;
      background-color: ${themeGet('colors.uiGreen', '#27E8A2')};
      box-shadow: 0 6px 9px rgba(50, 50, 93, 0.09),
        0 2px 5px rgba(0, 0, 0, 0.08);

      p {
        color: ${themeGet('colors.primary', '#27E8A2')};
        opacity: 0.8;
      }

      @media only screen and (max-width: 991px) {
        margin: 2rem -1rem;
      }

      li {
        margin: 2rem 2rem;
      }

      @media only screen and (max-width: 991px) {
        .icon-list {
          display: none !important;
        }
      }
    }
  }
`;

export const AccordionWrapper = styled.div`
  padding-right: 20px;

  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .accordion_item {
    border-bottom: 1px solid #ebebeb;
    border-top: 0;
  }

  .accordion_title {
    padding: 1.5rem 2rem;
    margin: 0;
    height: 5rem @media (max-width: 575px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .accordion_body {
    padding: 0 30px 23px 30px;
  }

  h4 {
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }


  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.uiGreen', '#27E8A2')};
    }
  }
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 1.6;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 1rem;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export default BlockWrapper;
