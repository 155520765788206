import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import BlockWrapper, { ContentWrapper, List, Item } from './problemBlock.style';

const ProblemBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        problemData {
          image {
            publicURL
          }
          slogan
          title
          listTitle
          listTitle2
          text
          footerText
          subtitle
          lists {
            id
            text
          }
          lists2 {
            id
            text
          }
        }
      }
    }
  `);

  const {
    slogan,
    title,
    subtitle,
    listTitle,
    listTitle2,
    text,
    lists,
    lists2,
    image,
    footerText,
  } = data.charityJson.problemData;

  return (
    <BlockWrapper id="problem">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading content="Remote Mentors helps your company face the challenges of remote work... at scale" />
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading as="h4" content="What if there was a solution for some of the biggest challenges remote companies face?" />
              {/* <Text content={text} /> */}
              <Heading as="h3" content={listTitle} />
              <List>
                {lists.map(item => (
                  <Item key={`list_key${item.id}`}>{item.text}</Item>
                  ))}
              </List>
              <hr/>
              <Heading as="h3" content="For end users Remote Mentors offers an engaging, interactive, asynchronous solution that give them access to remote best practices and a sense of togetherness." />
              <hr/>
              <br/>
              <br/>
              <Heading as="h3" content={listTitle2} />
              <List>
                {lists2.map(item => (
                  <Item key={`list_key${item.id}`}>{item.text}</Item>
                ))}
              </List>
              
              <hr />
              <Heading as="h3" content="For companies, Remote Mentors becomes a virtuous cycle solution that boost their employer brand as some of their internal mentors share knowledge with the world." />
              <hr />
              
              <AnchorLink
                className="smooth_scroll learn__more-btn"
                href="#ready"
              >
                <span className="hyphen" />
                <span className="btn_text">Get your pro pass</span>
              </AnchorLink>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// ProblemBlock style props
ProblemBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// ProblemBlock default style
ProblemBlock.defaultProps = {
  // ProblemBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: ['column', 'column', 'row'],
    ml: '-15px',
    mr: '-15px',
  },
  // ProblemBlock col default style
  col: {
    width: ['100%', '100%', '50%'],
    maxWidth: '500px',
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default ProblemBlock;
