import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Button } from '../Navbar/navbar.style';

const InnerWrapper = styled.div`
  min-height: 100vh;
  padding: 30px;
`;

export const SpreadButton = styled(Button)`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    width: calc(100% - 60px);
    width: calc(100% - 80px);
    position: absolute;
    left: 40px;
    bottom: 40px;
    min-height: 54px;
    border-radius: 10px;
    font-size: 1rem;
    overflow: hidden;
    z-index: 1;
    img {
      margin-left: 13px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      background: repeating-linear-gradient(
        -45deg,
        ${themeGet('colors.primary', '#27E8A2')},
        ${themeGet('colors.primary', '#27E8A2')} 10px,
        ${themeGet('colors.primaryHover', '#ECF22B')} 10px,
        ${themeGet('colors.primaryHover', '#ECF22B')} 20px
      );
      transition: all 0.45s ease;

      @media only screen and (max-width: 1440px) {
        background: repeating-linear-gradient(
          -45deg,
          ${themeGet('colors.primary', '#27E8A2')},
          ${themeGet('colors.primary', '#27E8A2')} 8px,
          ${themeGet('colors.primaryHover', '#ECF22B')} 8px,
          ${themeGet('colors.primaryHover', '#ECF22B')} 16px
        );
      }
    }

    &:hover {
      background-color: ${themeGet('colors.primary', '#27E8A2')};

      &::before {
        left: 0;
        opacity: 0.8;
        visibility: visible;
      }
    }
  }
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-left: 1.1rem;

  opacity: 0.6;

  @media only screen and (max-width: 480px) {
    margin: 0 auto;
  }

  li {
    margin: 0 5px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      line-height: 1;
      color: ${themeGet('colors.secondaryText', '#616970')};
      position: relative;
      @media only screen and (max-width: 480px) {
        width: 20px;
        heigt: 40px;
        margin-bottom: 10px;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: ${themeGet('colors.text', '#294859')};
        position: absolute;
        z-index: 0;
        opacity: 0;
        transform: scale(0.01);
        transition: all 0.35s ease;
      }

      i {
        position: relative;
        z-index: 1;

        svg {
          width: 18px;
          height: auto;
          @media only screen and (max-width: 1360px) {
            width: 22px;
          }
          @media only screen and (max-width: 580) {
            width: 22px;
          }
        }
      }
    }

    &.linkedin {
      a {
        &::before {
          background-color: ${themeGet('colors.linkedin', '#0077B5')};
        }
      }
    }

    &.facebook {
      a {
        &::before {
          background-color: ${themeGet('colors.facebook', '#3b5998')};
        }
      }
    }

    &.twitter {
      a {
        &::before {
          background-color: ${themeGet('colors.twitter', '#38A1F3')};
        }
      }
    }

    &.github {
      a {
        &::before {
          background-color: ${themeGet('colors.github', '#211f1f')};
        }
      }
    }

    &:hover {
      a {
        color: ${themeGet('colors.white', '#ffffff')};
        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
`;

export default InnerWrapper;
