import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import logoImage from 'common/src/assets/image/logos/SVG/logo-blue.svg';

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo" to="/">
          <Image src={logoImage} alt="Remote Mentors" />
        </Link>
        <MenuWrapper>
          <OutboundLink
            className="smooth_scroll"
            href="https://app.remotementors.io"
            target="_blank"
            offset={81}
          >
            Member access
          </OutboundLink>
          <Button className="btn_login">
            <Link to="/companies">
              <span className="text">Company portal</span>
            </Link>
          </Button>
          {/* <Button className="btn_login">
            <AnchorLink href="#ready" className="smooth_scroll">
              <span className="text">Signup</span>
            </AnchorLink>
          </Button> */}
          {/* <Image src={heartImage} alt="Charity Landing" /> */}
        </MenuWrapper>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
