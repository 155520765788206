import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;
  padding: 81px 0 51px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }

  .row {
    align-items: center;
  }

  .ctaContainer {
    @media only screen and (max-width: 991px) {
      max-width: 900px;
    }
  }
`;

export const ImageWrapper = styled.div`
  padding-right: 20px;
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  // h2 {
  //   font-size: 2rem;
  //   font-size: 1.6;
    
  //   margin-bottom: 40px;
  //   @media only screen and (max-width: 1440px) {
  //     font-size: 40px;
  //     line-height: 60px;
  //   }
  //   @media only screen and (max-width: 1360px) {
  //     font-size: 34px;
  //     line-height: 50px;
  //     margin-bottom: 27px;
  //   }
  //   @media only screen and (max-width: 991px) {
  //     font-size: 30px;
  //     line-height: 46px;
  //     margin-bottom: 20px;
  //   }
  //   @media only screen and (max-width: 767px) {
  //     font-size: 28px;
  //     line-height: 1.6;
  //   }
  // }

  // p {
  //   font-size: 18px;
  //   line-height: 28px;
  //   margin-bottom: 29px;
  //   color: ${themeGet('colors.text', '#294859')};
  //   @media only screen and (max-width: 1440px) {
  //     font-size: 1rem;
  //     margin-bottom: 27px;
  //   }
  //   @media only screen and (max-width: 1360px) {
  //     font-size: 1rem;
  //   }
  //   @media only screen and (max-width: 991px) {
  //     margin-bottom: 20px;
  //   }
  // }

  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.uiGreen', '#27E8A2')};
    }
  }
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 1.6;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 1rem;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const CallToActionButton = styled.button`
  border: 0;
  padding: 0;
  width: 100%;
  min-width: 150px;
  margin: 3rem auto 3rem;
  height: 3.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  border-radius: 5px;
  font-size: 1.5rem;
  padding: 1.6rem;
  max-width: 500px;
  transition: all 500ms ease-out;
  background-color: ${themeGet('colors.uiBlueLink', '#27E8A2')};
  border: 1px solid ${themeGet('colors.uiBlueLink', '#27E8A2')};

  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.09), 0 2px 5px rgba(0, 0, 0, 0.08);
  // box-shadow: 0 5px 50px -5px rgba(0,0,0,0.1); // egghead style

  a,
  a span {
    margin-right: 0;
    color: ${themeGet('colors.white', '#060F1E')};
  }

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.uiBlueLink', '#ECF22F')};
    // opacity: 0.8;
    content: 0;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 2px 3px rgba(0, 0, 0, 0.1);

    a,
    a span {
      color: ${themeGet('colors.white', '#060F1E')};
    }
  }

  @media only screen and (max-width: 1440px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 1360px) {
    // font-size: 14px;
  }

  img {
    margin-left: 11px;
    @media only screen and (max-width: 1440px) {
      margin-left: 10px;
    }
  }

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition: color 300ms;
  transition: color 300ms;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${themeGet('colors.uiBlueLinkHover', '#ECF22F')};
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 500ms ease-out;
    transition: 500ms ease-out;
  }
  &:hover:before {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
`;

export default BlockWrapper;
