import Drawer from 'common/src/components/Drawer';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { Icon } from 'react-icons-kit';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { productHunt } from 'react-icons-kit/fa/productHunt';
import { envelope } from 'react-icons-kit/fa/envelope';
import InnerWrapper, { SocialList } from './drawerSection.style';

const DrawerSection = () => {
  const [toggleState, setToggleState] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);

  const handleActiveClass = () => {
    setToggleState(!toggleState);
  };

  const handleDrawerToggle = () => {
    dispatch({
      type: 'TOGGLE',
    });
    handleActiveClass();
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        menuItems {
          path
          label
          offset
          externalLink
        }
      }
    }
  `);

  const scrollItems = [];

  data.charityJson.menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1));
  });

  return (
    <Drawer
      width="420px"
      placement="right"
      drawerHandler={
        <button
          className={`drawer_btn ${toggleState ? 'active' : ''}`}
          onClick={handleActiveClass}
          aria-label="drawer toggle button"
        >
          <ul className="grid">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <i className="flaticon-plus-symbol " />
        </button>
      }
      open={state.isOpen}
      toggleHandler={handleDrawerToggle}
    >
      <InnerWrapper>
        <Scrollspy
          className="scrollspy__menu"
          items={scrollItems}
          offset={-81}
          currentClassName="active"
        >
          {data.charityJson.menuItems.map((menu, index) => (
            <li key={`menu_key${index}`}>
              {!menu.externalLink && (
                <AnchorLink
                  href={menu.path}
                  offset={menu.offset}
                  onClick={handleDrawerToggle}
                >
                  {menu.label}
                </AnchorLink>
              )}

              {menu.externalLink && (
                <Link to={menu.path} offset={menu.offset}>
                  {menu.label}
                </Link>
              )}
            </li>
          ))}
        </Scrollspy>
        <SocialList>
          <li className="envelope">
            <a
              aria-label="go to mail"
              href="mailto:barefoot@remotementors.io&subject=RemoteMentors.io&body=Hi"
              className="mail"
            >
              <Icon icon={envelope} />
            </a>
          </li>
          {/* <li className="productHunt">
              <a
                href="https://www.linkedin.com/in/charleslouisallizard/"
                target="_blank"
                aria-label="social share link"
              >
                <Icon icon={productHunt} />
              </a>
            </li> */}
          {/* <li className="linkedin">
              <a
                href="https://www.linkedin.com/in/charleslouisallizard/"
                target="_blank"
                aria-label="social share link"
              >
                <Icon icon={linkedin} />
              </a>
            </li> */}

          {/* https://www.linkedin.com/company/remotementors */}

          <li className="facebook">
            <a
              href="https://www.facebook.com/Remote-Mentors-101992581581255"
              aria-label="social share link"
              target="_blank"
            >
              <Icon icon={facebook} />
            </a>
          </li>
          <li className="instagram">
            <a
              href="https://instagram.com/remotementors"
              aria-label="social share link"
              target="_blank"
            >
              <Icon icon={instagram} />
            </a>
          </li>
          {/* <li className="twitter">
              <a
                href="https://twitter.com/remotementors"
                aria-label="social share link"
                target="_blank"
              >
                <Icon icon={twitter} />
              </a>
            </li> */}
        </SocialList>
      </InnerWrapper>
    </Drawer>
  );
};

export default DrawerSection;
