import styled from 'styled-components';
import { themeGet } from 'styled-system';

const TestimonialSecWrapper = styled.section`
  padding: 80px 0;

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .row {
    align-items: center;
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .col {
    @media only screen and (max-width: 991px) {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 575px) {
    padding: 60px 0;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .glide {
    &:hover {
      .glide__prev--area,
      .glide__next--area {
        opacity: 1;
      }
    }
  }

  .glide__slides {
    padding-top: 10px;
    padding-bottom: 30px;

    .glide__slide {
      opacity: 0.5;
      pointer-events: none;
      transition: 0.25s ease;
      &.glide__slide--active {
        opacity: 1;
        pointer-events: auto;
        + .glide__slide {
          @media (min-width: 800px) {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
  }

  .glide__controls {
    position: static;
    .glide__prev--area,
    .glide__next--area {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: 0.15s ease-in-out;
      @media (max-width: 990px) {
        display: none;
      }

      button {
        font-size: 28px;
        cursor: pointer;
      }
    }
    .glide__prev--area {
      left: 10%;
      @media (max-width: 1400px) {
        left: 5%;
      }
    }
    .glide__next--area {
      right: 10%;
      @media (max-width: 1400px) {
        right: 5%;
      }
    }
  }

  @media (max-width: 990px) {
    .glide__slide--active .testimonial_item {
      box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.05);
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  // h2 {
  //   font-size: 2rem;
  //   font-size: 1.6;
    
  //   margin-bottom: 40px;
  //   @media only screen and (max-width: 1440px) {
  //     font-size: 40px;
  //     line-height: 60px;
  //   }
  //   @media only screen and (max-width: 1360px) {
  //     font-size: 34px;
  //     line-height: 50px;
  //     margin-bottom: 27px;
  //   }
  //   @media only screen and (max-width: 991px) {
  //     font-size: 30px;
  //     line-height: 46px;
  //     margin-bottom: 20px;
  //   }
  //   @media only screen and (max-width: 767px) {
  //     font-size: 28px;
  //     line-height: 1.6;
  //   }
  // }

  // p {
  //   font-size: 18px;
  //   line-height: 28px;
  //   margin-bottom: 29px;
  //   color: ${themeGet('colors.text', '#294859')};
  //   @media only screen and (max-width: 1440px) {
  //     font-size: 1rem;
  //     margin-bottom: 27px;
  //   }
  //   @media only screen and (max-width: 1360px) {
  //     font-size: 1rem;
  //   }
  //   @media only screen and (max-width: 991px) {
  //     margin-bottom: 20px;
  //   }
  // }

  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.primary', '#27E8A2')};
    }
  }
`;

export const TestimonialItem = styled.div`
  // border: 1px solid #f2f4f7;
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
  transition: 0.425s ease;
  border-radius: 4px;

  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.09), 0 2px 5px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0px 20px 40px -20px rgba(39, 79, 117, 0.25);
  }

  @media (max-width: 1300px) {
    padding: 30px;
  }
`;

export const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  flex-basis: 50px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 6px 30px 0px rgba(39, 79, 117, 0.2);
  margin-right: 15px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export default TestimonialSecWrapper;
