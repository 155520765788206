import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
} from './bannerSection.style';

const BannerSection = () => {
  const glideOptions = {
    // type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner">
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#mockup" />
      <ContentWrapper>
        <TextArea>
 
          <Heading
            as="h1"
            content="A virtuous cycle that creates togetherness and boost employer brand"
          />

          <AnchorLink
            className="smooth_scroll learn__more-btn"
            href="#mockup"
            offset={81}
          >
            <span className="hyphen" />
            <span className="btn_text">Remote Mentors for companies</span>
          </AnchorLink>
        </TextArea>
        <ImageArea>
          <GlideCarousel
            carouselSelector="bannerSlide"
            options={glideOptions}
            nextButton={<span className="next_arrow" />}
            prevButton={<span className="prev_arrow" />}
          >
            <Fragment>
              <Image
                fluid={
                  (data.charityJson.bannerSlides[0].thumb_url !== null) |
                  undefined
                    ? data.charityJson.bannerSlides[0].thumb_url.childImageSharp
                        .fluid
                    : {}
                }
                alt={`Remote mentors banner ${data.charityJson.bannerSlides[0].id}`}
                className="slide_image"
              />
              {/* {data.charityJson.bannerSlides.map(slide => (
                <GlideSlide key={slide.id}>
                </GlideSlide>
              ))} */}
            </Fragment>
          </GlideCarousel>
        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
