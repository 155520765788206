import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;
  padding: 81px 0 51px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }

  .row {
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .col {
    @media only screen and (max-width: 991px) {
      width: 100%;
      max-width: 700px;
    }
  }

  footer {
    padding: 1.5rem 2rem;

    @media only screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }

    a {
      color: ${themeGet('colors.uiBlueLink', '#4517ff')};
      &:hover {
        color: ${themeGet('colors.uiBlueLinkHover', '#4517ff')};
      }
    }

    .learn__more-btn {
      margin-top: 2rem;
    }
  }
`;

export const AccordionWrapper = styled.div`
  padding-right: 20px;

  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .accordion_item {
    border-bottom: 1px solid #ebebeb;
    border-top: 0;
  }

  .icon-accordion {
    color: ${themeGet('colors.uiBlueLink', '#27E8A2')};
  }

  .icon-accordion-minus {
    color: ${themeGet('colors.uiBlueLinkHover', '#27E8A2')};
  }

  .accordion_title {
    padding: 1.5rem 2rem;

    margin: 0;
    height: auto;

    @media only screen and (max-width: 991px) {
      padding: 1.5rem 2rem;
    }

    @media only screen and (max-width: 575px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .accordion_body {
    padding: 0 30px 23px 30px;
  }

  h4 {
    margin: 0;

    @media only screen and (max-width: 991px) {
      font-size: 1.25rem;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }


  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.uiGreen', '#27E8A2')};
    }
  }
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 1.6;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 1rem;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export default BlockWrapper;
