import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Image from 'gatsby-image';

import BlockWrapper, {
  ContentWrapper,
  ImageArea,
} from './portraitsSlider.style';

import {
  CallToActionButton,
} from '../CallToActionSection/callToActionSection.style';

const PortraitsSlider = ({ row, col }) => {
  const glideOptions = {
    type: 'carousel',
    perView: 6,
    gap: 15,
    autoplay: 1500,
    hoverpause: true,
    animationDuration: 2500,
    animationTimingFunc: 'ease-in-out',
    // rewind: true,
    // duration: 9,
    breakpoints: {
      300: {
        perView: 2,
        animationDuration: 1500,
        bullets: false,
      },
      600: {
        perView: 2,
        animationDuration: 1500,
        bullets: false,
      },
      1000: {
        perView: 5,
        animationDuration: 2000,
        bullets: false,
      },
      1200: {
        perView: 6,
        animationDuration: 2500,
        bullets: false,
      },
    },
    controls: false,
    bullets: false,
    // bulletWrapperStyle
  };

  const bulletWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        portraitsSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  //   const { slogan, title, text, lists, image } = data.charityJson.numberData;

  return (
    <BlockWrapper id="contributors">
      <ImageArea>
        <GlideCarousel
          carouselSelector="portraitSlide"
          options={glideOptions}
          //   controls={false}
          bullets={true}
          numberOfBullets={data.charityJson.portraitsSlides.length}
          bulletWrapperStyle={bulletWrapperStyle}
        >
          <Fragment>
            {data.charityJson.portraitsSlides.map(slide => (
              <GlideSlide key={slide.id}>
                <Image
                  fluid={
                    (slide.thumb_url !== null) | undefined
                      ? slide.thumb_url.childImageSharp.fluid
                      : {}
                  }
                  alt={`Charity slide image ${slide.id}`}
                  className="slide_image"
                />
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
      </ImageArea>
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading
                as="h5"
                content="A broad range of learning paths and profiles"
              />
              <Heading
                content={`Enter a positive feedback loop : ${'\n'} do yourself, your team and the world a favor and join Remote Mentors.`}
              />
              <Box id="readyBtn" className="row" {...row}>
                <CallToActionButton>
                  <Link to="https://charleslouisallizard.typeform.com/to/Hff1V7VJ" target="_blank">
                    <span className="text">Get in touch</span>
                  </Link>
                </CallToActionButton>
              </Box>              
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PortraitsSlider style props
PortraitsSlider.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PortraitsSlider default style
PortraitsSlider.defaultProps = {
  // PortraitsSlider row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PortraitsSlider col default style
  col: {
    width: ['100%', '100%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
    mt: ['100px', '120px', '150px', '200px'],
  },
};

export default PortraitsSlider;
