import styled from 'styled-components';
import { themeGet } from 'styled-system';
import bgImage from 'common/src/assets/image/charity/mockup/remote_mentors-cards-grid-by-nine.png';

const BlockWrapper = styled.div`
  width: 100%;
  margin: 81px 0;
  padding: 60px;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
  @media only screen and (max-width: 1440px) {
    margin: 70px 0;
    padding: 50px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 30px;
    margin: 50px 0;
  }
  @media only screen and (max-width: 991px) {
    margin: 40px 0;
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px;
    background-size: cover;
    margin-bottom: 40px;
  }

  .milestoneInner {
    background: red;
  }
`;

export const MilestoneInner = styled.div`
  background: linear-gradient(
    0deg,
    rgba(75, 15, 209, 1) 0%,
    rgba(69, 23, 240, 1) 100%
  );
  padding: 4rem 0;
  h3,
  p {
    color: #fff;
  }

  h3 {
    font-size: 3rem;
  }
`;

export const MilestoneCard = styled.div`
  max-width: 323px;
  padding: 62px 55px 70px;
  border-radius: 10px;
  background-color: ${themeGet('colors.primary', '#27E8A2')};
  text-align: center;
  @media only screen and (max-width: 1440px) {
    padding: 56px 45px 56px;
  }
  @media only screen and (max-width: 1360px) {
    max-width: 300px;
    padding: 45px 30px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 280px;
    padding: 40px 30px;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: ${themeGet('colors.heading', '#060F1E')};
    margin: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
      line-height: 1.6;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 1rem;
      line-height: 28px;
    }
  }


  .learn__more-btn {
    margin-top: 40px;
    @media only screen and (max-width: 1440px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 1360px) {
      margin-top: 40px;
    }

    &::before {
      // background-color:  ${themeGet(
        'colors.uiGreen',
        '#060F1E'
      )}; !important;
    }
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
`;

export const CounterItem = styled.div`
  text-align: center;
  @media only screen and (max-width: 480px) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 680px) {
    width: 50%;
    flex-grow: 1;
    padding: 1rem 0;
  }

  h3 {
    font-size: 3rem;
    line-height: 60px;

    @media only screen and (max-width: 1440px) {
      font-size: 40px;
      line-height: 46px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 34px;
      line-height: 1.6;
      margin-bottom: 13px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 2px;

    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 991px) {
      font-size: 1rem;
    }
  }
`;

export default BlockWrapper;
